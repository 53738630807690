import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {getPrice, getFirstListingImage, getTitle, addResizeParams } from '../../utils/listingHelper';
import get from 'lodash/get';
import { getListingReactUrlParams } from '../../utils/urlHelpers/boat';
import { DEFAULT_NO_BOAT_IMAGE } from '../../constants/boats';
import { setProductImpression, setListingClick } from '../../store/actions/dataLayer';
import { HorizontalGallery, ListingCarouselCard } from '@dmm/lib-react-ui-components';
import { getBreakpoint } from '../../utils/commonHelper';
import './styles.css';

const listingsElements = (listings, listingsType, listingTrackingClick, page, isDesktop) => {
  const elements = listings.map((listing, index) => {
    const listingImage = getFirstListingImage(listing);
    return <div key={`${listingsType}CarouselElement${index}`} className="carouselElement">
      <ListingCarouselCard
        onClick = {() => setListingClick(listing.id, listingsType, '', '', getTitle(listing), -1, listing.make)}
        image = {listingImage ? addResizeParams(get(listingImage, 'original.url', listingImage.url), {width: 250, height: 180}, listingImage.date.modified, true, 'webp') : DEFAULT_NO_BOAT_IMAGE}
        imageAlt = {getTitle(listing)}
        price = {getPrice(listing)}
        name = {getTitle(listing)}
        sellerMarketingMessage = {get(listing, 'owner.name', '')}
        defaultImage = {DEFAULT_NO_BOAT_IMAGE}
        hyperlinkAttributes = {{
          'data-e2e': 'listingCarouselLink',
          'data-reporting-click-product-id': listing.id,
          'data-reporting-click-listing-type': listingTrackingClick,
          'data-reporting-rank': index,
          'data-reporting-page': page,
          href: getListingReactUrlParams(listing).href,
          target: isDesktop ? '_blank' : '_self'
        }}
      />
    </div>;
  });
  return elements;
};

export const ListingsCarousel = (
  {
    listings = [],
    listingsType,
    title, subtitle,
    listingTrackingClick,
    page
  }) => {
  const dispatch = useDispatch();
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    setIsDesktop(getBreakpoint() === 'desktop');
    listings.forEach((listing, position) => {

      let { id } = listing;
      dispatch(setProductImpression(id, listingsType, position, undefined, undefined, undefined, undefined, undefined, getTitle(listing), listing.make));
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (listings.length === 0){
    return null;
  }
  const childrenElements = listingsElements(listings, listingsType, listingTrackingClick, page, isDesktop);
  return <div className="listingCarouselContainer" data-e2e="listingCarouselContainer">
    <HorizontalGallery
      title = {title}
      subtitle = {subtitle}
      leftButtonAttributes = {{
        'data-reporting-click-internal-link-type': 'click',
        'data-reporting-click-internal-link-id': 'Move Slider Left',
        'data-e2e': 'move-slider-left'
      }}
      rightButtonAttributes = {{
        'data-reporting-click-internal-link-type': 'click',
        'data-reporting-click-internal-link-id': 'Move Slider Right',
        'data-e2e': 'move-slider-right'
      }}
    >
      {childrenElements}
    </HorizontalGallery>
  </div>;
};
