import { ContactCard, FinanceSummary, SummarySection } from '@dmm/lib-react-ui-components';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { withCookies } from 'react-cookie';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withABTest } from '../../server/abTest/withABTest';
import { addLead, setGenericEvent, trackPhoneCall } from '../../store/actions/dataLayer';
import {
  getLocation,
  getPrice,
  getPriceCut,
  getTitle,
  isFSBOContact,
  roundLength
} from '../../utils/listingHelper';
import { scrollTo } from '../../utils/scrollTo';
import { calculateMonthlyPrice, isFinanceable } from '../../utils/trident';
import Loading from '../LoadingPlaceholder';
import './styles.css';

const BoatSummary = ({
  breakpoint,
  isLoading,
  listing,
  tridentTeaserRate,
  tridentLoanAmount,
  tridentTermInMonths,
  onClickPaymentTotal,
  onClickPaymentLink,
  onClickLocationLink,
  bannerText,
  showSellerInfoCard
}) => {
  const [paymentCalculatorCounter, setPaymentCalculatorCounter] = useState(1);
  const [locationMapCounter, setLocationMapCounter] = useState(1);

  const handlePaymentCalculatorLinkClick = event => {
    event.preventDefault();
    scrollTo('#payment-calculator-anchor', { behavior: 'smooth' });
    onClickPaymentLink(paymentCalculatorCounter);
    setPaymentCalculatorCounter(prevCounter => prevCounter + 1);
  };

  const handleLocationMapLinkClick = event => {
    event.preventDefault();
    window.requestAnimationFrame(() => {
      scrollTo('#location-map-anchor', { behavior: 'smooth' });
    });
    onClickLocationLink(locationMapCounter);
    setLocationMapCounter(prevCounter => prevCounter + 1);
  };

  const renderPriceTotal = () => {
    if (breakpoint === 'desktop') {
      return (<span>{getPrice(listing)}</span>);
    }
    return (<a onClick={onClickPaymentTotal}>{getPrice(listing)}</a>);
  };

  const dispatch = useDispatch();
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);
  const contactCardSrc = listing.owner?.logos?.default || '';
  const contactCardSellerName = listing.owner?.name;
  const contactCardPhoneNumber = listing.contact?.phone;
  const leadSubmitted = useSelector((state) => get(state, 'app.additionalLeads.open', false));

  const handleClickPhoneCall = () => {
    dispatch(trackPhoneCall());
    dispatch(addLead(listing.Id, 'phone call'));
  };

  useEffect(() => {
    if (leadSubmitted === true) { setShowConfirmationMessage(true); }
  }, [leadSubmitted]);

  const renderSummary = () => {
    const boatLength = `| ${roundLength(get(listing, 'specifications.dimensions.lengths.nominal.ft'))}'`;
    const hasExactLocation = !isFSBOContact(listing.contact);
    const price = get(listing, 'price.type.amount.USD');
    const financeable = isFinanceable(price, listing?.year);
    const priceDrop = getPriceCut(listing);

    return (
      <div className="broker-summary-section">
        <SummarySection
          status={bannerText}
          title={getTitle(listing, true)}
          price={renderPriceTotal()}
          priceDrop={priceDrop ? priceDrop : ''}
          location={getLocation(listing)}
          locationClassNames={{ 'content-16': true }}
          onClickLocation={getLocation(listing) && hasExactLocation && handleLocationMapLinkClick}
          boatLength={boatLength}
        />
        {showSellerInfoCard &&
          <div className="contact-card-container">
            <ContactCard
              src={contactCardSrc}
              listedByText="Listed by"
              sellerName={contactCardSellerName}
              phoneNumber={contactCardPhoneNumber}
              showConfirmationMessage={showConfirmationMessage}
              handleClick={onClickPaymentTotal}
              onClickPhoneNumber={handleClickPhoneCall}
            />
          </div>
        }
        {financeable && monthlyPrice && (
          <div className="finance-summary">
            <FinanceSummary
              paymentText={`Own this boat for $${monthlyPrice}/month`}
              linkText={'Customize'}
              onClickCustomize={handlePaymentCalculatorLinkClick}
              content={<div>Estimate include principle interest, taxes and insurance.</div>}
              useHover={false}
              background="#FFFFFF"
              direction="up-start"
              className="finance-summary-tooltip"
              maxWidth="unset"
            />
          </div>
        )}
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <>
        <Loading type="text" />
        <Loading type="text" />
        <Loading type="text" />
      </>
    );
  };

  const monthlyPrice = calculateMonthlyPrice(tridentTeaserRate, tridentTermInMonths, tridentLoanAmount);

  return (
    <div className="summary" data-testid="boat-summary">
      {isLoading || !listing.id ?
        renderLoading()
        :
        renderSummary()
      }
    </div>
  );
};

export default withABTest(withCookies(connect(null, (dispatch) => bindActionCreators({
  setGenericEvent
}, dispatch))(BoatSummary)));
