/* eslint-disable no-console */
import { formatNumber, formatPriceWithDecimal } from '@dmm/lib-common/lib/formatting';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import { MAX_AGE_TO_FINANCE, MIN_PRICE_TO_FINANCE, BOAT_LEAD_PURCHASE_TYPE } from '../../constants/BoatLoans';
import { getPrice, roundLength, isFSBOContact } from '../listingHelper';
import { preQualClickTracking, fetchFinanceCheckboxExperiment } from '../experiment/kameleoonHelper';
import { CTA_APPLY_NOW_LINK_ROOT, CTA_PREQUAL_LINK_ROOT } from '../../constants/BoatLoansCalculator';
import { getMarketingParamsFromSessionStorage, overrideMarketingParams } from '../urlHelpers/utmParams';
import { clearEmptyProps } from '../commonHelper';
import * as httpClient from '../httpClient';

export const createBoatInfoParam = (listing) => {

  let boatLength;
  if (listing.specifications && get(listing, 'specifications.dimensions.lengths.nominal.ft')) {
    boatLength = roundLength(get(listing, 'specifications.dimensions.lengths.nominal.ft'));
  }

  let totalPower = 0;
  listing?.propulsion?.engines?.forEach(engine => {
    totalPower += engine.power?.hp || 0;
  });

  const engineData = listing?.propulsion?.engines && listing.propulsion.engines.length > 0 ? listing.propulsion.engines[0] : null;
  const engineMake = engineData?.make;

  return encodeURIComponent(JSON.stringify({
    price: get(listing, 'price.type.amount.USD'),
    type: capitalize(get(listing, 'type')),
    year: get(listing, 'year'),
    make: get(listing, 'make'),
    model: get(listing, 'model'),
    imtId: get(listing, 'id'),
    length: boatLength,
    partyId: get(listing, 'owner.id'),
    engineCount: get(listing, 'propulsion.engines.length'),
    engineMake,
    fuelType: get(listing, 'fuelType'),
    totalPower,
    sellerName: get(listing, 'owner.name'),
    sellerEmail: get(listing, 'contact.email'),
    sellerType: isFSBOContact(listing.contact) ? 'FSBO' : 'Broker/Dealer',
    sellerFinanceAdvantage: get(listing, 'owner.isFinanceAdvantage', false),
  }));
};

export const calculateMonthlyPrice = (rate, term, loanAmount, roundNumber = true) => {
  if (!loanAmount || !term || (!rate && rate !== 0)) {return;}

  let parsedRate = rate;
  let loanAmountAsNumber = loanAmount;

  if (typeof loanAmountAsNumber !== 'number') {
    loanAmountAsNumber = parseInt(loanAmount.replace(/[$,]/g, ''));

    if (!loanAmountAsNumber) {return;}
  }

  if (typeof parsedRate === 'string') {
    parsedRate = parseFloat(rate);
  }

  const ratePerMonth = parsedRate / 100 / 12;
  const x = Math.pow(1 + ratePerMonth, term);
  let monthlyPayment = (loanAmountAsNumber * ratePerMonth * x) / (x - 1);

  if (parsedRate === 0 && term > 0) {
    monthlyPayment = loanAmountAsNumber / term;
  } else if (roundNumber){
    monthlyPayment = Math.round((loanAmountAsNumber * ratePerMonth * x) / (x - 1), 2);
    return formatNumber(monthlyPayment);
  }

  return formatPriceWithDecimal(monthlyPayment, 'USD', 'en-US', 2);

};

export const isFinanceable = (price, boatYear) => {
  const boatAge = new Date().getFullYear() - (boatYear || 0);
  const boatPrice = price || 0;
  return boatPrice >= MIN_PRICE_TO_FINANCE && boatAge <= MAX_AGE_TO_FINANCE;
};

/**
 * @param {{ isExperiment: boolean, utmCampaign: string, utmContent?: string, listing?: any}} param
 * @returns
*/
export const getApplyNowLink = ({ isVariant, utmCampaign, utmContent, listing }) => {
  const baseUrl = isVariant ? CTA_PREQUAL_LINK_ROOT : CTA_APPLY_NOW_LINK_ROOT;
  let boatInfo;

  if (listing) {
    boatInfo = createBoatInfoParam(listing);
  }

  let ctaLink = `${baseUrl}&utm_campaign=${utmCampaign}${utmContent ? `&utm_content=${utmContent}` : ''}${boatInfo ? `&boatInfo=${boatInfo}` : ''}`;
  const marketingParamsFromStorage = getMarketingParamsFromSessionStorage();
  if (Object.keys(marketingParamsFromStorage).length !== 0){
    ctaLink = overrideMarketingParams(ctaLink, marketingParamsFromStorage);
  }

  return ctaLink;
};

export const checkPreQualVariation = async ({utmCampaign, utmContent, listing}) =>{
  const variant = await preQualClickTracking();
  const link = getApplyNowLink({ isVariant: variant === 'on', utmCampaign, utmContent, listing });
  window.open(link, '_blank');
};

export const showCalculator = (listing, hideTridentFeatures) => {
  const hiddenPrice = get(listing, 'price.hidden') || !listing?.price?.type?.amount || listing?.price?.type?.amount.USD <= 1;
  const boatYear = get(listing, 'year');
  const boatPrice = get(listing, 'price.type.amount.USD');

  return (!hiddenPrice && isFinanceable(boatPrice, boatYear) || hideTridentFeatures);
};

export const isSegmentCandidateForFinance = (listing) =>{
  const hideTridentFeatures = listing?.owner?.hideTridentFeatures ?? false;
  const isOemModel = listing?.isOemModel;

  const hiddenPrice = getPrice(listing) === 'Request a Price';
  const price = get(listing, 'price.type.amount.USD');
  const financeable = isFinanceable(price, listing?.year);

  return !hideTridentFeatures && !isOemModel && !hiddenPrice && financeable;
};

export const isSegmentCandidateForBrokerDealer = (listing) =>{
  const isPrivateSeller = isFSBOContact(listing.contact || listing.owner);

  return !isPrivateSeller;
};

export const isSegmentCandidateForFinanceAdvantage = (listing) => {
  const isFinanceAdvantage = listing?.owner?.isFinanceAdvantage ?? false;
  return isFinanceAdvantage;
};

const isValidEngineType = (engineType) => {
  return engineType && (engineType.toLowerCase() === 'gas' || engineType.toLowerCase() === 'diesel' || engineType.toLowerCase() === 'gasoline');
};

export const makePrivateSummaryTrackingEvent = (actionLabel, actionType) => {
  return {
    'event': 'link_click',
    'action_type': actionType,
    'action_label': actionLabel,
    'ga4': {
      page: {
        'detailed_page_name': 'boat details',
        'section': 'listings',
        'subsection': 'boat listings'
      }
    }
  };
};

export const makeTrackingEvent = (actionLabel, actionType) => {
  return {
    'event': 'link_click',
    'action_type': actionType,
    'action_label': actionLabel,
  };
};

export const createInquiryFinanceLead = async (data, listing, leadPage = 'BDP-ContactForm') => {
  const fullName = (data?.name || '').split(' ');
  const firstName = fullName[0];
  const lastName = fullName[fullName.length - 1];

  let leadPayload = {
    firstName,
    lastName,
    email: data?.email,
    phone: data?.phone,
    leadSource: 101458,
    leadPage,
    leadType: BOAT_LEAD_PURCHASE_TYPE,
    interestedInFinancing: true,
    utmContent: data?.utmContent,
    utmCampaign: data?.utmCampaign,
  };

  if (listing) {
    const {
      year,
      model,
      location,
      make,
      type: boatType,
      owner,
      price,
      id: listingId,
      portalLink: listingURL,
      specifications,
      propulsion,
      fuelType: engineType,
      hull
    } = listing;
    const purchasePrice = !price?.hidden && price?.type?.amount && price?.type?.amount.USD > 1 ? price?.type?.amount.USD : undefined;
    const sellerName = owner?.name;
    const partyId = owner?.id;
    const listingCity = location?.address?.city;
    const listingState = location?.address?.state;
    const length = specifications?.dimensions?.lengths?.nominal?.ft;
    const engineCount = propulsion?.engines?.length || 0;
    const manufacturer = encodeURIComponent(make);
    const boatName = `${manufacturer} ${model}`;
    const seller = encodeURIComponent(sellerName);
    const sellerPartyId = parseInt(partyId);
    const hin = hull?.hin;

    leadPayload = {
      ...leadPayload,
      year,
      model,
      manufacturer,
      boatType,
      boatName,
      length,
      seller,
      sellerPartyId,
      listingCity,
      listingState,
      listingId,
      listingURL,
      engineCount,
      ...(isValidEngineType(engineType) && { engineType: engineType.replace('gasoline', 'gas') }),
      purchasePrice,
      hin,
    };
  }

  return clearEmptyProps(leadPayload);
};

export const sendFinanceLead = async (leadData) => {
  const urlLead = `${process.env.REACT_APP_TRIDENT_API_URL}/lead`;
  let apiClient = httpClient.getHttpClient();
  try {
    return await apiClient.post(urlLead, leadData);
  } catch (err) {
    console.error('sendFinanceLead | err', err);
    throw err;
  }
};

export const isFinanceCheckboxExperiment = async (listing) =>{
  const variant = await fetchFinanceCheckboxExperiment(listing);
  return variant === 'on';
};
