import { BOAT_LEAD_PURCHASE_TYPE, TRACKING_EVENT_LEAD_PURCHASE_SUBMISSION } from '../../../../constants/BoatLoans';
import { BRAND_BANNER_IMPRESSION } from '../../../../constants/boats';
import { overflowContainer } from '../../../../utils';
import { getBreakpoint } from '../../../../utils/commonHelper';
import { buildProduct, isEventSent } from '../../../../utils/dataLayerHelper';
import { scrollTo } from '../../../../utils/scrollTo';
import { useBdpActions } from './bdpActions';

export const BDP_ANCHOR = 'root-anchor';
const ANCHOR = `#${BDP_ANCHOR}`;

export const useBdpHandlers = (setState) => {
  const {
    trackContactFormOpen,
    openCarouselModal,
    setModalEvent,
    showAdditionalLeadsModal,
    setProduct,
    setOemBrandProductImpression,
    setListingView,
    setGenericEvent
  } = useBdpActions();
  const hideModals = () => {
    scrollTo(ANCHOR);
    setState({
      showModalCarousel: false,
      showModalDealerLocator: false,
      showModalImmersiveTour: false,
      showModalPostLead: false
    });
    overflowContainer(false);
  };

  const openCarousel = (index = 0) => {
    openCarouselModal();
    setState({
      carouselIndex: index,
      showModalCarousel: true
    });
    overflowContainer(true);
  };

  const handleCloseContactForm = () => {
    setState({
      openContactForm: false,
      UIEvent: {}
    });
  };

  const handleOpenContactForm = (e, UIEvent = {}) => {
    trackContactFormOpen();
    setState({openContactForm: true, UIEvent});
  };

  const handleTrackOpenContactForm = (e, UIEvent = {}, label) => {
    handleOpenContactForm(e, UIEvent);
    setModalEvent('modal open', label, 'modal action');
  };

  const openModalDealerLocator = () => {
    setState({showModalDealerLocator: true});
  };

  const displayModalPreQualified = (show, leadType = BOAT_LEAD_PURCHASE_TYPE, leadPage = 'BDP', trackingEvent = {'event': TRACKING_EVENT_LEAD_PURCHASE_SUBMISSION}, abTest) => {
    setState({showModalPreQualified: show, leadType, leadPage, trackingEvent, tridentABTest: abTest});
  };

  const openImmersiveTourModal = () => {
    setState({showModalImmersiveTour: true});
  };

  const openModalPostLead = (leadData) => {
    showAdditionalLeadsModal(leadData);
  };

  const closeImmersiveTourModal = () => {
    setState({showModalImmersiveTour: false});
  };

  const resizeHandler = () => {
    setState({breakpoint: getBreakpoint()});
  };

  /**
   * @param {*} listing used to set the product in the dataLayer
   */
  const setProductData = (listing) => {
    if (listing && listing.id) {
      setProduct(buildProduct(listing, 'boat'));
    }
  };

  const setOemBrandProductImpressionData = (listing, sent) => {
    /* istanbul ignore next */
    if (isEventSent('pageview') && !sent) {
      /* istanbul ignore next */
      setOemBrandProductImpression(
        listing.oemDetails.brandId,
        BRAND_BANNER_IMPRESSION,
        BRAND_BANNER_IMPRESSION
      );
      /* istanbul ignore next */
      setState({oemBrandProductImpressionSent: true});
    }
  };

  const setListingViewData = (listing, sent) => {
    /* istanbul ignore next */
    if (isEventSent('pageview') && !sent) {
      /* istanbul ignore next */
      setListingView(listing);
      /* istanbul ignore next */
      setState({setListingViewSent: true});
    }
  };

  return {
    openCarousel,
    hideModals,
    handleOpenContactForm,
    handleCloseContactForm,
    handleTrackOpenContactForm,
    openModalDealerLocator,
    openModalPostLead,
    displayModalPreQualified,
    openImmersiveTourModal,
    closeImmersiveTourModal,
    resizeHandler,
    setProductData,
    setOemBrandProductImpressionData,
    setListingViewData,
    setGenericEvent
  };
};

export const createCarouselButtons = (mediaImages, mediaVideos, showModalCarousel) => {
  const buttons = [
    {
      title: `View ${mediaImages.length} ${mediaImages.length > 1 ? 'Photos' : 'Photo'}`,
      onClick: () => showModalCarousel(0),
      show: mediaImages.length > 0
    },
    {
      title: 'Video',
      onClick: () => {
        showModalCarousel(mediaImages.length > 0 ? 1 : 0);
      },
      show: mediaVideos.length > 0
    }
  ];
  return buttons;
};

export const createSummaryHandlers = (setState) => {
  const handleSummaryPaymentLinkClicked = (componentCounter) => {
    setState({
      openPaymentComponent: 'payment-calculator',
      openPaymentComponentCounter: componentCounter
    });
  };

  const handleSummaryLocationLinkClicked = (componentCounter) => {
    setState({
      openLocationComponent: 'location-map',
      openLocationComponentCounter: componentCounter
    });
  };

  const handleSummaryLoansButtonclicked = (componentCounter) => {
    setState({
      openBoatLoansComponent: 'boat-loans',
      openBoatLoansComponentCounter: componentCounter
    });
  };

  const handleBoatSummaryScroll = (scrolledPastBoatSummary) => {
    setState({
      scrolledPastBoatSummary: scrolledPastBoatSummary
    });
  };
  return {
    handleSummaryPaymentLinkClicked,
    handleSummaryLocationLinkClicked,
    handleSummaryLoansButtonclicked,
    handleBoatSummaryScroll
  };
};

//TODO: Separate state properly for each component.
export const parseDetailsSectionState = (state) => {
  const detailsSectionState = {
    openPaymentSection: state.openPaymentComponent || '',
    openPaymentCounter: state.openPaymentComponentCounter || 0,
    openLocationSection: state.openLocationComponent || '',
    openLocationCounter: state.openLocationComponentCounter || 0,
    openBoatLoansSection: state.openBoatLoansComponent,
    openBoatLoansCounter: state.openBoatLoansComponentCounter || 0,
    showModalPreQualified: state.showModalPreQualified
  };
  return detailsSectionState;
};
