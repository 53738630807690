import React, { PureComponent } from 'react';
import './styles.css';
import LoadingPlaceholder from '../../../components/LoadingPlaceholder';
import ContactGroup from '../ContactGroup';

class StickyContact extends PureComponent {

  render() {
    const { isLoading } = this.props;
    return (
      <div className={`sticky-contact ${this.props.fadeButtons ? 'fade' : ''}`}>
        {isLoading
          ? this.renderLoading()
          : this.renderContact()}
      </div>
    );
  }

  renderLoading() {
    return <LoadingPlaceholder type="text" />;
  }

  renderContact() {
    return <ContactGroup {...this.props} />;
  }
}

export default StickyContact;

